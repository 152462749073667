<template>
  <main class="codox-editor">
    <main>
      <quill-editor ref="editor" v-model="content"/>
    </main>
    <footer>
      <v-btn
        color="primary"
        depressed
        large
        @click="saveActivity"
      >{{ $t('save') }}
      </v-btn>
    </footer>
  </main>

</template>

<script>
import { mapGetters } from 'vuex'
import { ActivityNoteProxy } from '@/proxies/ActivityNoteProxy'

export default {
  name: 'Codox',
  props: {
    docId: {
      required: true,
    },
    eventId: {
      required: true
    }
  },
  data () {
    return {
      content: ''
    }
  },
  mounted () {
    const config = {
      'app': 'quilljs',
      'editor': this.$refs.editor.quill,
      'docId': this.docId,
      'user': {
        'name': this.getUserName || 'Anonymous',
      },
      'apiKey': process.env.VUE_APP_CODOX_API_KEY,
    }
    const codox = new Codox()
    codox.init(config)
  },
  methods: {
    saveActivity () {
      new ActivityNoteProxy()
        .setParameter('eventId', this.eventId)
        .patch({
          item: {
            activityNotes: this.content
          }
        })
    }
  },
  computed: {
    ...mapGetters('account', ['getUserName'])
  }
}
</script>

<style lang="scss">
.codox-editor {
  height: 100%;
  display: flex;
  flex-direction: column;

  main {
    flex: 1;
  }

  footer {
    display: flex;
    margin-top: 15px;
    justify-content: flex-end;
  }
}

</style>
