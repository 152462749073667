var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"tickets container-fluid align-center px-6 pt-6 pb-0"},[_c('v-row',{attrs:{"gutters":3,"pa-2":""}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isVisible),expression:"isVisible"}],staticClass:"ticket-overlay",on:{"click":function($event){_vm.isVisible=!_vm.isVisible}}}),_c('v-col',{staticClass:"sidebar-container"},[_c('div',{staticClass:"sidebar",class:_vm.isVisible ? 'visible':''},[_c('h3',[_vm._v(_vm._s(_vm.$t('New Requests')))]),_c('v-list',[_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.filters.caseStatus),callback:function ($$v) {_vm.$set(_vm.filters, "caseStatus", $$v)},expression:"filters.caseStatus"}},[_vm._l((_vm.status),function(item,i){return [_c('v-list-item',{key:("status-" + i),class:_vm.filters.caseStatus == item.value? 'primary--text text--accent-4':'',attrs:{"value":item.id,"active-class":"primary--text text--accent-4"}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}})],1),_c('v-list-item-icon',[_c('svg-icon',{attrs:{"icon":item.icon}})],1)],1)]})],2)],1),(_vm.userProfile == 'MANAGER')?_c('div',[_c('h4',[_vm._v(_vm._s(_vm.$t('Request Status')))]),_c('v-list',[_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.filters.requestStatus),callback:function ($$v) {_vm.$set(_vm.filters, "requestStatus", $$v)},expression:"filters.requestStatus"}},[_vm._l((_vm.requestStatus),function(item,i){return [_c('v-list-item',{key:("item-" + i),attrs:{"value":item.value,"active-class":"primary--text text--accent-4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}})],1),_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary accent-4"}})],1)]}}],null,true)})]})],2)],1)],1):_vm._e(),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"font-12",attrs:{"value":_vm.computedDateFormattedMomentjs,"label":_vm.$t('Select date range'),"prepend-icon":"mdi-calendar","readonly":"","clearable":""},on:{"click:clear":function($event){_vm.filters.dates = []}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.datepicker_view),callback:function ($$v) {_vm.datepicker_view=$$v},expression:"datepicker_view"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","range":""},model:{value:(_vm.filters.dates),callback:function ($$v) {_vm.$set(_vm.filters, "dates", $$v)},expression:"filters.dates"}})],1),(_vm.userProfile == 'MANAGER' && _vm.getProjects && _vm.getProjects[1])?_c('div',[_c('h4',[_vm._v(_vm._s(_vm.$t('Projects')))]),_c('v-list',[_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.filters.projectId),callback:function ($$v) {_vm.$set(_vm.filters, "projectId", $$v)},expression:"filters.projectId"}},[_vm._l((_vm.getProjects),function(item,i){return [_c('v-list-item',{key:("item-" + i),attrs:{"value":item.record_id,"active-class":"primary--text text--accent-4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.project_name)}})],1),_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary accent-4"}})],1)]}}],null,true)})]})],2)],1)],1):_vm._e(),(_vm.userProfile == 'MANAGER')?_c('div',[_c('h4',[_vm._v(_vm._s(_vm.$t('Brand')))]),_c('v-list',[_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.filters.brand),callback:function ($$v) {_vm.$set(_vm.filters, "brand", $$v)},expression:"filters.brand"}},[_vm._l((_vm.brands),function(item,i){return [_c('v-list-item',{key:("item-" + i),attrs:{"value":item.id,"active-class":"primary--text text--accent-4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.label)}})],1),_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary accent-4"}})],1)]}}],null,true)})]})],2)],1)],1):_vm._e(),(_vm.userProfile == 'MANAGER')?_c('div',[_c('h4',[_vm._v(_vm._s(_vm.$t('Priority')))]),_c('v-list',[_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.filters.priority),callback:function ($$v) {_vm.$set(_vm.filters, "priority", $$v)},expression:"filters.priority"}},[_vm._l((_vm.priority),function(item,i){return [(!item.hide)?_c('v-list-item',{key:("item-" + i),attrs:{"value":item.value,"active-class":"primary--text text--accent-4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}})],1),_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary accent-4"}})],1)]}}],null,true)}):_vm._e()]})],2)],1)],1):_vm._e(),_c('h4',[_vm._v(_vm._s(_vm.$t('Categories')))]),(_vm.getCategories && _vm.getCategories[0])?_c('div',[_c('v-treeview',{attrs:{"selectable":"","item-disabled":"locked","items":_vm.groupCategories(
                _vm.getCategories,
                'parent_category',
                'category_name',
                'record_id'
              )},model:{value:(_vm.filters.category),callback:function ($$v) {_vm.$set(_vm.filters, "category", $$v)},expression:"filters.category"}})],1):_vm._e()],1)]),_c('v-col',[_c('v-card',[_c('v-card-title',[_c('v-btn',{staticClass:"btn-mobile",attrs:{"color":"primary","depressed":"","large":""},on:{"click":function($event){_vm.isVisible=!_vm.isVisible}}},[_vm._v(" FILTER ")]),_c('div',{staticStyle:{"margin-left":"10px","margin-top":"-12px"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('Search'),"single-line":"","clearable":"","hide-details":""},on:{"click:clear":function($event){_vm.searchKeyword='';_vm.filters.keyword='';}},model:{value:(_vm.searchKeyword),callback:function ($$v) {_vm.searchKeyword=$$v},expression:"searchKeyword"}})],1),_c('v-spacer'),_c('v-spacer'),_c('v-btn',{staticClass:"btn-desktop btn-mini-size",staticStyle:{"padding-top":"15px"},attrs:{"color":"primary","to":{ name: 'createTicket', query: {part:'request'} },"depressed":"","large":""}},[_vm._v(_vm._s(_vm.$t('Create New Request'))+" ")]),_c('v-btn',{staticClass:"btn-mobile btn-mini-size",staticStyle:{"padding-top":"15px"},attrs:{"color":"primary","to":{ name: 'createTicket' },"depressed":"","large":""}},[_c('i',{staticClass:"fal fa-lg fa-plus"})]),(_vm.userProfile == 'MANAGER')?_c('v-btn',{staticClass:"btn-mini-size",attrs:{"color":"success","href":_vm.url,"depressed":"","large":""}},[_c('i',{staticClass:"fal fa-lg fa-file-excel"})]):_vm._e()],1),_c('v-data-table',{staticClass:"elevation-1 tickets-table",attrs:{"headers":_vm.headers,"items":_vm.tickets,"items-per-page":_vm.filters.rowsPerPage,"server-items-length":_vm.total,"page":_vm.filters.page,"sort-by":_vm.filters.sort.name,"loading":_vm.loading,"sort-desc":_vm.filters.sort.desc,"footer-props":{
            'items-per-page-options': [25, 50, 100],
          }},on:{"update:itemsPerPage":function($event){return _vm.$set(_vm.filters, "rowsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.filters, "rowsPerPage", $event)},"update:page":function($event){return _vm.$set(_vm.filters, "page", $event)},"update:sortBy":function($event){return _vm.$set(_vm.filters.sort, "name", $event)},"update:sort-by":function($event){return _vm.$set(_vm.filters.sort, "name", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.filters.sort, "desc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.filters.sort, "desc", $event)}},scopedSlots:_vm._u([{key:"item.record_id",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"inline-col"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({},'a',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true),model:{value:(item.threadOptionVisibility),callback:function ($$v) {_vm.$set(item, "threadOptionVisibility", $$v)},expression:"item.threadOptionVisibility"}},[_c('v-card',[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('Settings'))+" ")])],1)],1)],1),_c('v-divider'),_c('v-list',[_c('v-list-item',[_c('v-list-item-action',[_c('v-select',{attrs:{"label":_vm.$t('Priority'),"items":_vm.priorities,"outlined":"","item-text":"name","item-value":"value","menu-props":{
                            zIndex: 101,
                          },"dense":"","flat":"","hide-details":""},on:{"change":function($event){return _vm.updatePriority(item)}},model:{value:(item.priority),callback:function ($$v) {_vm.$set(item, "priority", $$v)},expression:"item.priority"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){item.threadOptionVisibility = false}}},[_vm._v(" "+_vm._s(_vm.$t('Cancel'))+" ")])],1)],1)],1),_c('router-link',{attrs:{"to":{ name: 'ticketDetail', params: { id: item.record_id } }}},[_vm._v(" "+_vm._s(item.record_id)+" ")])],1)]}},{key:"item.subject",fn:function(ref){
                          var item = ref.item;
return [_c('div',{staticClass:"subject-field"},[_c('span',[_vm._v(_vm._s(item.sender_name)+" "+_vm._s(item.sender_email ? ("<" + (item.sender_email) + ">") : ''))]),_c('router-link',{attrs:{"to":{ name: 'ticketDetail', params: { id: item.record_id } }}},[_vm._v(" "+_vm._s(item.subject)+" ")])],1)]}},{key:"item.priority",fn:function(ref){
                          var item = ref.item;
return [(item.priority)?_c('div',[_c('i',{class:'fal ' + item.cfd_priority_value,style:({ color: '#'+item.cfd_priority_color }),attrs:{"title":item.cfd_priority_label}})]):_vm._e()]}},{key:"item.request_status",fn:function(ref){
                          var item = ref.item;
return [_c('v-chip',{staticStyle:{"width":"180px"},attrs:{"color":'#' + item.cfd_request_status_color + _vm.opacity,"dark":"","title":item.cfd_request_status_label}},[_c('span',{staticClass:"circle",style:({background: '#' + item.cfd_request_status_color})}),_c('span',{staticClass:"max-100",style:({color:'#' + item.cfd_request_status_color}),attrs:{"title":item.cfd_request_status_label}},[_vm._v(_vm._s(item.cfd_request_status_label))])])]}},{key:"item.created_on",fn:function(ref){
                          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setTime(item.created_on))+" ")]}},{key:"item.last_message_date",fn:function(ref){
                          var item = ref.item;
return [_vm._v(" "+_vm._s(item.last_message_date ? _vm.setTime(item.last_message_date) : 'Not Updated')+" ")]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }